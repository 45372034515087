.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a:link {
  text-decoration: none;
  color: #008AFF
}
a:visited {
  text-decoration: none;
  color: #008AFF
}
a:hover {
  text-decoration: none;
  color: #008AFF
}
a:active {
  text-decoration: none;
  color: #008AFF
}

.center {
  text-align: center !important;
}

.left {
  text-align: left;
  margin-left: 10px;
}

.top {
  /* margin-top: 3%; */
  padding-top: 3%;
}

.right {
  text-align: right;
  margin-right: 3%;
}

.bold {
  font-weight: bold;
  /* margin-left: 2px; */
}

.spinnerCenter {
  text-align: -webkit-center;
}

.wrap {
  margin: auto;
  padding-top: 160px;
  padding-bottom: 3.8125rem;
}

.logo-header {
  margin-bottom: 30px;
  text-align: center;
  border-bottom-style: ridge;
  border-bottom-color: #A1A5AC;
  border-width: 0.3px;
  padding: 20px;
}

.spacer {
  padding-bottom: 1rem;
}

.addressSpacer {
  padding-top: 2.75rem;
  padding-bottom: 3.275rem;
}

.confirmedSpacer {
  padding-bottom: 3.275rem;
}

.downloadSpacer {
  padding-bottom: 1.275rem;
}

.download-header-title {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-bottom: 25px;
}

.innerSpacer {
  padding-top: 1.775rem;
  padding-bottom: 2.275rem;
}

.affiliateSpacer {
  padding-bottom: 1rem;
}

.container {
  width: 100%;
  min-height: calc(70vh - 40px);
  margin: 0px auto;
}

.input-control {
  appearance: none;
  background-color: #F3F1F1;
  border: 0 none;
  border-radius: 10px;
  color: #3D3D3D;
  display: block;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.5px;
  outline-color: #008AFF;
  outline-width: 2px;
  width: 100%;
}

.input-control-bold {
  appearance: none;
  background-color: #F3F1F1;
  border: 0 none;
  border-radius: 10px;
  color: #3D3D3D;
  display: block;
  padding: 10px 20px;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: -0.5px;
  outline: none;
  width: 100%;
  font-weight: bold;
}

.input-control-wrapper {
  position: relative;
  margin-bottom: 20px;
}

.input-control:disabled {
  color: #acacac;
}

.input-control-submit-wrapper {
  text-align: center;
}

.input-control-row {
  display: flex;
  gap: 18px;
}

.pi-control-wrapper .input-control {
  padding-right: 40px;
}

.pi-control-visibility-toggle {
  appearance: none;
  background-color: transparent;
  border: 0 none;
  position: absolute;
  right: 5px;
  top: 0;
  padding: 10px;
  color: #3d3d3d;
} 

.header3 {
  color: #3D3D3D;
  margin-bottom: 30px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-style: normal;
  font-weight: bold;
}

.header3-left {
  text-align: left;
  color: #3D3D3D;
  margin-bottom: 30px;
  margin-left: 10px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-style: normal;
  font-weight: bold;
}

.header4 {
  color: #3D3D3D;
  margin-bottom: 20px;
}


.header5 {
  color: #3d3d3d;
}

.headerWarning {
  color: #3D3D3D;
  margin-top: 48px;
  line-height: 25px;
}

.headerError {
  color: #ff0000;
  margin-top: 48px;
  line-height: 25px;
}

.appIconSpacer {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
}

.description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 20px;
  padding-right: 10px;
}

.description-grey {
  color: #777777;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 12px;
  padding-right: 10px;
  margin-left: 10px;
}

.input-title {
  color: #000000;
  font-weight: bold;
  font-size: 16.9317px;
  line-height: 20px;
  align-items: left;
  text-align: left;
  margin-bottom: 15px;
}

.address,
.mobile {
  color: #000000;
  width: 100%;
}

.password-form {
  margin: 0 auto;
  max-width: 346px;
  width: 100%;
}

.address-form {
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
}

.address-form-avatar {
  text-align: left;
  margin-left: 10px;
  margin-bottom: 30px;
}

.circle-avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.affiliateCode {
  position: relative;
  bottom: 3.25em;
  left: 21.75em;
  font-size: 12px;
  color: #A1A5AC;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.button {
  width: 100%;
  max-width: 260px;
  height: 60px;
  background-color: #008AFF;
  color: #FFFFFF;
  border: 0;
  border-radius: 10px;
  font-size: 25px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: -0.5px;
  text-align: center;
  margin-bottom: 60px;
}

.button-full-width {
  width: 100%;
  height: 50px;
  background-color: #008AFF;
  color: #FFFFFF;
  border: 0;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  margin-bottom: 60px;
}

.button-header {
  font-size: 20px;
  text-align: left;
  padding-top: 5%;
  margin-left: 20px;
}

.button-price {
  font-size: 15px;
  margin-left: 20px;
}

.change-price {
  position: relative;
  left: 160px;
  bottom: 15px;
  font-size: 15px;
}

.change-price.offer-margin {
  left: 115px !important;
}

.manage_plan_buttons {
  appearance: none;
  background-color: transparent;
  font-size: 20px;
  border: 0 none;
  position: relative;
  left: 170px;
  bottom: 10px;
  margin-top: -13px;
}

.manage_plan_buttons.offer-margin {
  left: 125px !important;
}

.grey {
  background-color: #F3F1F1;
  border-radius: 10px;
  padding-bottom: 20px;
}

.card-description {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 1px;
}

.card-border {
  padding: 20px 0px 20px;
  background-color: #F3F1F1;
  border-radius: 10px;
  margin-bottom: -15px;
}

.card-element {
  padding: 0px 15px 0 15px !important;
}

.errorMessage {
  color: #ff0000;
  line-height: 20px;
  font-size: 13px;
  padding-bottom: 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.get-uome{
  margin-top: 3em;
  padding-top: 2.5em;
  border-top-style: ridge;
  border-top-color: #A1A5AC;
  border-width: 0.3px;
}

.get-uome-header {
  text-align: left;
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.5px;
    font-style: normal;
    font-weight: bold;
}

.get-uome-description {
  font-size: 13px;
  line-height: 18px;
  padding: 12px;
}

.get-uome-app-icon-spacer {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 15px;
}

.footer {
  background-color: #3D3D3D;
  bottom: 0;
  width: 100%;
  padding: 15px;
  margin-top: 9rem;
  object-position: bottom;
}

.footer-description {
  background-color: #3D3D3D;
  font-size: 11px;
  color: #f6f6f6;
  margin-top: 10px;
}

.footer-logo {
  padding: 10px;
  width: 60px;
}

.token-expired-description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 18px;
  padding-inline: 30px;
}

.something-wrong-description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 18px;
  padding-inline: 30px;
}

/* Mobile CSS Header */

@media (max-width: 480px) {
  .logo-header {
    margin-bottom: 30px;
    text-align: left;
    padding-left: 30px;
   }

  .header3 {
    text-align: left;
    color: #3D3D3D;
    margin-bottom: 30px;
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
    font-style: normal;
    font-weight: bold;
  }
}

/* Universal Payments */

.button-payment-option {
  width: 100%;
  height: 50px;
  background-color: #008AFF;
  color: #FFFFFF;
  border: 0;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  margin-bottom: 25px;
}

.klarna-payment-option {
  width: 100%;
  height: 50px;
  background-color: #FAB0C7;
  color: #FFFFFF;
  border: 0;
  border-radius: 10px;
  font-size: 22px;
  font-weight: bold;
  line-height: 25px;
  text-align: center;
  margin-bottom: 20px;
}

.payment-option-spacer {
  padding-bottom: 1rem;
}

.order-description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 10px;
}

.items-description {
  color: #3d3d3d;
  font-size: 15px;
}

.items-padding {
  margin-right: 5px !important;
}

.pay-to-description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 10px;
  padding-right: 10px;
  margin-top: -15px;
  margin-bottom: 30px;
}

.sub-total-description {
  color: #3d3d3d;
  font-size: 15px;
  line-height: 10px;
}

.total-description {
  color: #3d3d3d;
  font-size: 18px;
  font-weight: bold;
  line-height: 10px;
}

.torn-paper-container {
  position: relative;
  background-color: #ffffff;
  margin-bottom: 10px;
}

.torn-paper-container::before,
.torn-paper-container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
}

.torn-paper-container::before {
  content: '';
  position: absolute;
  top: -4px; 
  left: 0;
  right: 0;
  height: 4px;
  background-image: url('../src/images/receipt-torn-edge-top.png');
  background-repeat: repeat;
}

.torn-paper-container::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px; 
  background-image: url('../src/images/receipt-torn-edge-bottom.png');
  background-repeat: repeat;
}

.tip-header {
  text-align: left;
  color: #3D3D3D;
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.5px;
  font-style: normal;
  font-weight: bold;
}

.tip-description {
  text-align: left;
  color: #777777;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
  font-size: 14px;
  letter-spacing: -0.3px;
  font-style: normal;
}

.tip-input-control-wrapper {
  position: relative;
}

.fraud-description {
  text-align: left;
  color: #777777;
  margin-left: 10px;
  margin-bottom: 0px;
  font-size: 14px;
  font-style: normal;
}

.white {
  color: #f6f6f6
}

.original-price {
  text-decoration: line-through;
  color: #777777;
  margin-right: 0.5em;
}

.discounted-price {
  color: #008AFF;
  font-weight: bold;
}